
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import MotorBreakdown from "@/components/reusable/policy-period/MotorBreakdown.vue";
import OwnerBuildingBreakdown from "@/components/reusable/policy-period/OwnerBuildingBreakdown.vue";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import store from "@/store";
import { useRouter } from "vue-router";
import * as Yup from "yup";

export default defineComponent({
  name: "insurance-packages-policy-period",
  components: {
    Form,
    Field,
    ErrorMessage,
    TotalPremiumWidget,
    MotorBreakdown,
    OwnerBuildingBreakdown,
  },
  props: ["publicId"],
  data() {
    return {
      currentDate: variables.currentDate(),
      startDate: variables.currentDate(),
      startDateMax: "" as any,
      endDateMin: variables.currentDate(),
      endDateMax: "" as any,
      endDate: "",
      cartItem: this.$route.params.publicId,
      // publicId: this.$route.params.publicId,
      vehicles: [],
      newVehicles: [],
      summary: {
        totalPremium: 0 as number,
        invoiceNumber: "",
      },
      newSummary: {
        totalPremium: 0 as number,
      },
      initialTotalPremium: 0 as number,
      displayBreakdown: false,
      newDuration: "",
      shortCode: "",
      building: {
        buildingType: "",
        reinstatementValue: 0 as number,
        lossOfRentValue: 0 as number,
      },
      newBuilding: {
        buildingType: "",
        reinstatementValue: 0 as number,
        lossOfRentValue: 0 as number,
      },
    };
  },
  setup(props) {
    const router = useRouter();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const validateFields = Yup.object().shape({
      startDate: Yup.date()
        .required()
        // .min(Yup.ref("startDate"))
        .label("policy start date"),
      endDate: Yup.date()
        .required()
        // .min(
        //   [Yup.ref("startDate"), null],
        //   "Policy end date cannot be later than the start date"
        // )
        .label("policy end date"),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Policy Period", [
        "Insurance Packages",
        "Buy Insurance",
      ]);
    });

    const updateCartItem = (values) => {
      const payload = {
        startDate: values["startDate"],
        endDate: values["endDate"],
        cartItem: props.publicId,
      } as object;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.patch(
          variables.CART_ITEM_ROUTE + props.publicId + "/policy-period",
          payload
        )
          .then(({ data }) => {
            //Assign data to form fields
            variables.toastAlert(data.data, "success");
            router.push({
              name: "insurance-packages-cart-debit-note",
              params: {
                publicId: props.publicId,
              },
            });
          })
          .catch(function(error) {
            console.log(error);
            // variables.toastAlert(error.data, "error");
          });
      }
    };

    return { user, validateFields, updateCartItem };
  },
  created() {
    const router = useRouter();

    // Redirect back to policy details if publicId does not exist
    if (this.publicId == null) {
      router.push({ name: "insurance-packages-cart" });
    }

    this.startDateMax = variables.addYearToDate(variables.currentDate(), 1);
    this.endDateMax = variables.addYearToDate(variables.currentDate(), 1);
    setTimeout(() => {
      this.endDate = this.endDateMax;
    }, 1000);
    this.getCartItemVehicles();

    //Set page title
    document.title =
      "Insurance Package Policy Period | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getCartItemVehicles() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CART_ITEM_ROUTE}${this.publicId}/items`)

          .then(({ data }) => {
            this.shortCode = data.data.businessTypeShortCode;

            if (this.shortCode == variables.MOTOR_SHORT_CODE)
              this.vehicles = data.data.vehicles;

            if (this.shortCode == variables.OWNED_BUILDING_SHORT_CODE)
              this.building = data.data.ownedBuilding;

            //Assign data to form fields
            this.summary.totalPremium = data.data.totalPremium;
            this.initialTotalPremium = data.data.totalPremium;
            this.summary.invoiceNumber = data.data.invoiceNumber;

            if (
              data.data.endorsementType !== undefined &&
              data.data.endorsementType === "Renewal"
            ) {
              this.currentDate = data.data.startDate;
              this.startDate = data.data.startDate;
              this.startDateMax = variables.addYearToDate(
                data.data.startDate,
                1
              );

              this.endDate = variables.addYearToDate(data.data.startDate, 1);
              this.endDateMin = data.data.startDate;
              // this.endDateMax = variables.addYearToDate(data.data.startDate, 1);
              this.endDateMax = data.data.endDate;
            }
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
            // router.push({ name: "insurance-packages-cart" });
          });
      }
    },
    getStartDate(event) {
      this.startDateMax = variables.addYearToDate(event.target.value, 2);
      this.endDateMax = variables.addYearToDate(event.target.value, 1);
      this.endDateMin = this.startDate;
    },
    getProrataPremium() {
      const payload = {
        startDate: this.startDate,
        endDate: this.endDate,
        cartItem: this.publicId,
      } as object;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(
          `${variables.CART_ITEM_ROUTE}${this.publicId}/prorate`,
          payload
        )
          .then(({ data }) => {
            //Assign data to form fields
            this.summary.totalPremium = data.data.totalPremium;
            this.newVehicles = data.data.vehicles;
            this.newSummary.totalPremium = data.data.totalPremium;
            this.newDuration = data.data.duration;
            this.newBuilding = data.data.ownedBuilding;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
